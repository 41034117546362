.spotify-widget {
    position: fixed;
    top: 100px;
    right: 50px;
    z-index: 100;
    width: 25%;
    height: 390px;

    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
}

.spotify-widget iframe {
    display: block;
    width: 100%;
    height: 100%;
    border: none;
}

.music-player {
    font-weight: 500;
    color: white;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 1450px) {
    .spotify-widget {
        top: auto;
        bottom: -180px;
        left: 50%;
        width: 300px;
        height: 350px;
        /* transform: translateX(-130%); */
        transform: translateX(-130%);
        /* display: flex;
        justify-content: center; */
    }
}

@media screen and (max-width: 950px) {
    .spotify-widget {
        top: auto;
        bottom: -180px;
        left: 45%;
        width: 300px;
        height: 350px;
        /* transform: translateX(-130%); */
        transform: translateX(-100%);
        /* display: flex;
        justify-content: center; */
    }
}

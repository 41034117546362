* {
  padding: 0;
  margin: 0;
  /* font-family: 'SF Pro'; */
}

body {
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "SF Pro Display", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* height: 100vh; */
    /* or a specific height like 800px */
  /* overflow-y: auto; */
    /* Allows vertical scrolling when content overflows */
  /* overflow-x: hidden; */
  /* overflow: hidden; */
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 950px) {
  body {
    height: 100vh;
    /* Adjust height for smaller screens */
    overflow-y: auto;
    /* box-sizing: border-box; */
    /* padding-top: 60px; */
  }
}

.content {
  padding-top: 60px;
  /* Space for the navbar */
  margin: 0;
  /* Reset margin if needed */
  /* Other styles for content */
}

/* @font-face {
  font-family: 'SF Pro';
  src: url('/SFPRODISPLAYREGULAR.OTF') format('opentype');
  font-weight: 400;
  font-style: normal;
} */

/* @font-face {
  font-family: 'SF Pro';
  src: url('/SFPRODISPLAYBOLD.OTF') format('opentype');
  font-weight: 700;
  font-style: normal;
} */

/* @font-face {
  font-family: 'SF Pro';
  src: url('/SFPRODISPLAYBLACKITALIC.OTF') format('opentype');
  font-weight: 900;
  font-style: italic;
} */

/* body {
  font-family: 'SF Pro', -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* Navigation.css */

nav {
    position: fixed;
    top: 32%;
    left: 20px;
    transform: translateY(-50%);
    width: 200px;
    z-index: 1000;
    padding: 10px;
    display: flex;
    /* align-items: center; */
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

li {
    margin: 15px 0;
}

nav a {
    text-decoration: none;
    color: white;
    font-size: 18px;
    font-weight: 500;
    display: block;
    /* Make the link fill the entire area for better clickability */
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

nav a:hover {
    text-shadow: 0 0 8px rgba(255, 255, 255, 0.8);
    /* White glow effect */
    transform: translateY(-5px);
    /* Slight upward movement */
}

nav a:hover img {
    filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.8));
    /* Glowing effect for icons */
}

.about-icon {
    width: 70px;
}

.project-icon  {
    width: 70px;
}

.connect-icon {
    width: 70px;
}

@media screen and (max-width: 1450px) {
    nav {
            top: 0;
            left: 0;
            transform: none;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            padding: 15px 0;
        }

        ul {
            flex-direction: row;
            width: 100%;
            padding: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 40px;
        }

        li {
            margin: 0;
        }

        nav a {
            flex-direction: row;
            gap: 10px;
        }
}

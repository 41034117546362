.connect-window h4 {
    font-weight: 500;
    color: white;
}

.connect-body {
    height: 525px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.connect-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.connect-info {
    padding: 20px;
}

.email {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    margin-right: 10px;
}

.email h4 {
    font-weight: 550;
}

.email-icon {
    font-size: 25px;
}

.connect-window .about-window {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

ul {
    list-style: none;
}

.example-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    bottom: 100px;
    /* Adjust as needed */
    right: 100px;
    /* Adjust as needed */
}

.example-2 .icon-content {
    margin: 0 10px;
    position: relative;
}

.example-2 .icon-content .tooltip {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    padding: 6px 10px;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    font-size: 14px;
    transition: all 0.3s ease;
}

.example-2 .icon-content:hover .tooltip {
    opacity: 1;
    visibility: visible;
    top: -50px;
}

.example-2 .icon-content a {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: #4d4d4d;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
}

.example-2 .icon-content a:hover {
    box-shadow: 3px 2px 45px 0px rgb(0 0 0 / 12%);
}

.example-2 .icon-content a svg {
    position: relative;
    z-index: 1;
    width: 30px;
    height: 30px;
}

.example-2 .icon-content a:hover {
    color: white;
}

.example-2 .icon-content a .filled {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: #000;
    transition: all 0.3s ease-in-out;
}

.example-2 .icon-content a:hover .filled {
    height: 100%;
}

.example-2 .icon-content a[data-social="linkedin"] .filled,
.example-2 .icon-content a[data-social="linkedin"]~.tooltip {
    background-color: #0274b3;
    cursor: pointer;
}

.example-2 .icon-content a[data-social="github"] .filled,
.example-2 .icon-content a[data-social="github"]~.tooltip {
    background-color: #24262a;
    cursor: pointer;
}

.example-2 .icon-content a[data-social="resume"] .filled,
.example-2 .icon-content a[data-social="resume"]~.tooltip {
    background-color: #FE86B3;
    cursor: pointer;
}


.carousel {
    position: relative;
    width: 100%;
    /* width: 300px; */
    max-width: 600px;
    /* Adjust as needed */
    overflow: hidden;
    margin: 20px auto;
    border-radius: 10px;
}

.carousel-track {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.carousel-item {
    min-width: 100%;
    width: 300px;
}

.carousel-item img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 10px;
    width: 250px;
}

.carousel-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
}

.carousel-btn.prev {
    left: 10px;
}

.carousel-btn.next {
    right: 10px;
}

.carousel-btn:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.about-window {
    box-sizing: border-box;
    width: 800px;
    height: 600px;
    margin-left: 250px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #2828289b;
    /* backdrop-filter: blur(15px); */
    color: white;
}

.about-top {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-sizing: border-box;
    padding: 7px;
    background-color: #282828d6;
    backdrop-filter: blur(10px);
    max-width: 800px;
    margin-left: 250px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-top: 28px;
}

.window-buttons {
    display: flex;
    gap: 8px;
    margin-left: 10px;
}

.about-top h4 {
    color: white;
    font-weight: 500;
}

.window-button {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ff5f57;
    /* Close (red) */
}

.window-button:nth-child(2) {
    background-color: #ffbd2e;
    /* Minimize (yellow) */
}

.window-button:nth-child(3) {
    background-color: #28c840;
    /* Fullscreen (green) */
}

.about-bottom {
    display: flex;
    flex-direction: row;
    gap: 40px;
    padding: 30px;
    align-items: center;
}

.about-window .divider {
    display: block;
    height: 2px;
    width: 375px;
    background-color: #FE86B3;
    margin-top: 10px;
    margin-bottom: 15px;
}

.about-window .profile-image {
    width: 300px;
    /* border: 2px solid white; */
    border-radius: 20px;
}

.about-right {
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 35px;
}

.about-right h1 {
    font-weight: 600;
}

.about-right h4 {
    font-weight: 500;
}

/* .project-nav {
    margin-top: 30px;
    border: 2px solid black;
    border-radius: 10px;
    height: 50px;
    width: 100%;
    overflow: hidden;
} */

/* .project-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    text-align: center;
    text-decoration: none;
    color: black;
    background-color: #D8D5D6;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.project-button:hover {
    background-color: #fe88b3c2;
    color: white;
} */

.project-nav {
    text-decoration: none;
}

.project-button {
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 16px 36px;
    border: 4px solid;
    border-color: transparent;
    font-size: 16px;
    background-color: inherit;
    border-radius: 100px;
    font-weight: 600;
    color: #FE86B3;
    box-shadow: 0 0 0 2px #FE86B3;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
    margin-top: 15px;
}

.project-button svg {
    position: absolute;
    width: 24px;
    fill: #FE86B3;
    z-index: 9;
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.project-button .arr-1 {
    right: 16px;
}

.project-button .arr-2 {
    left: -25%;
}

.project-button .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background-color: #FE86B3;
    border-radius: 50%;
    opacity: 0;
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.project-button .text {
    /* text-decoration: none; */
    position: relative;
    z-index: 1;
    transform: translateX(-12px);
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.project-button:hover {
    box-shadow: 0 0 0 12px transparent;
    color: #212121;
    border-radius: 12px;
}

.project-button:hover .arr-1 {
    right: -25%;
}

.project-button:hover .arr-2 {
    left: 16px;
}

.project-button:hover .text {
    transform: translateX(12px);
}

.project-button:hover svg {
    fill: #212121;
}

.project-button:active {
    scale: 0.95;
    box-shadow: 0 0 0 4px #FE86B3;
}

.project-button:hover .circle {
    width: 280px;
    height: 250px;
    opacity: 1;
}

@media screen and (max-width: 1450px) {
    .about-window {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
        width: 80%;
        max-width: 800px;
        height: 550px;
        margin-top: 130px;
    }
    .about-top {
        width: 98%;
        padding: 5px;
        margin-top: 10px;
        /* margin-left: 5px; */
    }
}

@media screen and (max-width: 950px) {
    .about-window {
            display: flex;
            flex-direction: column;
            /* margin-top: 300px; */
        }

        .about-bottom {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            padding: 20px;
        }

        .profile-image {
            width: 100%;
            max-width: 300px;
        }

        .about-right {
            width: 100%;
            max-width: 400px;
            text-align: center;
        }

        .project-nav {
            width: 100%;
        }

        .about-top {
            width: 98%;
            padding: 5px;
            margin-top: 10px;
        }
}
